import React, { useEffect } from "react";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router";
import ProfileDropDown from "../profile/ProfileDropdown";
import notification from "../../assets/icons/notification.svg";
import logo from "../../assets/icons/ims-logo.svg";
import ProfilePic from "../../assets/icons/my-profile.svg";
import Logout from "../../assets/icons/logout.svg";
import ScreenMirror from "../../assets/icons/screenmirroring.svg";
import SelectCourse from "../../assets/icons/selectcourse.svg";
import profileImage from "../../assets/images/profile-pic.png";

import SubHeader from "./SubHeader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getHeaderDetail,
  getSubheaderOption,
  getCurrentSubHeaderTab,
} from "../../redux/features/header/headerSlice";

export const tabOptions = [
  {
    id: 1,
    name: "Courseware",
    path: "/courseware",
    subHeaderOptions: [
      {
        id: "courseware1",
        name: "All Courses",
        path: "/courses",
      },
      {
        id: "courseware2",
        name: "Add new course",
        path: "/addcourse",
      },
      {
        id: "courseware3",
        name: "Content Repository",
        path: "/contentRepository",
      },
      {
        id: "courseware4",
        name: "Campaign Form",
        path: "/campaignForm",
      },
      {
        id: "courseware5",
        name: "Master",
        path: "",
      },
    ],
  },
  {
    id: 2,
    name: "Variants",
    path: "/Variants",
    subHeaderOptions: [
      {
        id: 1,
        name: "Variant Management",
        path: "/variants",
      },
      {
        id: 2,
        name: "Category Management",
        path: "/category",
      },
    ],
  },
  {
    id: 3,
    name: "Slot Booking",
    path: "/test",
  },
  {
    id: 45,
    name: "Slot Management",
    path: "/slot-management",
  },
  {
    id: 46,
    name: "Venue Management",
    path: "/venue-management",
  },
  {
    id: 4,
    name: "Performance",
  },
  {
    id: 5,
    name: "Home Page",
    path: "/performance",
    subHeaderOptions: [
      {
        id: "mypage1",
        name: "Banner Management",
        path: "/home-page/slide-listing",
      },
      {
        id: "mypage2",
        name: "Blog Management",
        path: "/blogs",
      },
      {
        id: "mypage3",
        name: "Calendar Management",
        path: "/homepage/calendar",
      },
      {
        id: "mypage4",
        name: "Noticeboard Management",
        path: "/noticeboardlisting",
      },
      {
        id: "mypage5",
        name: "Communication Management",
        path: "/communication",
      },
    ],
  },
  { id: 6, name: "School", path: "/school" },
  { id: 7, name: "Reports", path: "/community" },
];

const dropdownData = [
  { id: 1, title: "IMS-ID", src: ScreenMirror, path: "/home" },
  { id: 2, title: "Profile", src: ProfilePic, path: "/My-profile" },
  {
    id: 3,
    title: "Access Management",
    src: SelectCourse,
    path: "/access-Management",
  },
  { id: 4, title: "Logout", src: Logout, path: process.env.REACT_APP_IMSROUTE },
];

const Header = () => {
  const dispatch = useDispatch();
  const tabOption = useSelector((state) => state.loginData.LoginDetails);
  const history = useHistory();
  const currentHeaderTab = useSelector(
    (state) => state.HeaderData.currentHeaderTab
  );
  const subHeaderOptions = useSelector(
    (state) => state.HeaderData.subHeaderOptions
  );

  const handleOnMouseOver = (headerTabObject) => {
    dispatch(getHeaderDetail(headerTabObject.module_name));
    dispatch(getSubheaderOption(headerTabObject.subModules));
  };

  const handleOnClick = (headerTabObject) => {
    if (headerTabObject.module_name === "Home") {
      history.push("/");
    }
    if (headerTabObject.module_name.toLowerCase() === "slot management") {
      history.push("/slot-management");
    }
    if (headerTabObject.module_name.toLowerCase() === "venue management") {
      history.push("/venue-management");
    }
    if (headerTabObject.module_name === "Mentor MyPlan List") {
      history.push("/mentor-listing");
    }
    if (headerTabObject.module_name === "Advertisement") {
      history.push("/AdvertiseMent");
    }
  };
  let headerData = tabOption?.productModuleAccessData?.filter(
    (item) => item.module_name != "Access Management"
  );

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        if (window.location.pathname !== "/") {
          dispatch(
            getCurrentSubHeaderTab(window.location.pathname.split("/")[2])
          );
          dispatch(getHeaderDetail(window.location.pathname.split("/")[1]));
          const resHeader = window.location.pathname.split("/")[1];
          const res = tabOption.productModuleAccessData
            .filter((x) => x.module_name.replace(/ /g, "").includes(resHeader))
            .map((y) => y.subModules);
          dispatch(getSubheaderOption(res[0]));
        }
        if (window.location.pathname === "/") {
          dispatch(getCurrentSubHeaderTab(""));
          dispatch(getHeaderDetail(""));
          dispatch(getSubheaderOption(""));
        }
      }
    });
  }, []);

  return (
    <div>
      <div
        className={`w-full bg-secondary-100  sticky z-10 ${
          subHeaderOptions ? "border-b-[1px] border-[#E5E5E5]" : "shadow-outer"
        } `}
      >
        <header className="h-20 w-[95%] mx-auto  flex flex-row justify-between items-center ">
          {/* Left Side */}
          <img
            className="h-16 w-16 cursor-pointer"
            src={logo}
            alt="My IMS Logo"
            onClick={() => history.push("/")}
          />

          {/* Middle */}
          <div className="flex flex-1 ">
            {tabOption &&
              headerData
                ?.filter(
                  (name) =>
                    name.viewAccess !== "0" ||
                    name.editAccess !== "0" ||
                    name.deleteAccess !== "0"
                )
                .map((option) => (
                  <div
                    key={option.product_module_id}
                    className={`ml-12 font-normal text-lg font-ibm cursor-pointer ${
                      option.module_name
                        .toLowerCase()
                        .replace(/['"]+/g, "")
                        .replace(/ /g, "") ===
                      currentHeaderTab.toLowerCase().replace(/ /g, "")
                        ? "text-lg text-primary-200"
                        : ""
                    }  `}
                    onMouseOver={() => {
                      handleOnMouseOver(option);
                    }}
                    onClick={() => {
                      // handleOnMouseOver(option);
                      handleOnClick(option);
                    }}
                  >
                    {option.module_name}
                  </div>
                ))}
          </div>

          {/* Right Side */}
          <div className="flex flex-row items-center space-x-4 ">
            <Badge
              badgeContent={500}
              color="primary"
              overlap="circular"
              max={9}
            >
              <img
                className="cursor-pointer"
                src={notification}
                alt="Notification Icon"
              />
            </Badge>
            <ProfileDropDown item={dropdownData} menuIcon={profileImage} />
            {/* menuText="Guide"*/}
          </div>
        </header>
      </div>
      {subHeaderOptions && (
        <SubHeader
          subHeaderData={subHeaderOptions}
          currentHeaderTab={currentHeaderTab}
        />
      )}
    </div>
  );
};

export default Header;
