import React from "react";
import Item from "./Item";
import { ReactComponent as Cancel } from "../../../assets/icons/cancelBlack.svg";
import propTypes from "prop-types";

function browse({
  sectionName,
  section_id,
  prevData,
  setTemplateLink,
  url,
  setIsUploading,
  isPredictedPercentile,
}) {
  function clearFunction() {
    if (sectionName.toLowerCase() === "overall") {
      setTemplateLink({
        ...prevData,
        overAll: "",
      });
    } else if (sectionName.toLowerCase() === "rank") {
      setTemplateLink("");
    } else {
      let temp = JSON.parse(JSON.stringify(prevData));
      let sectionIndex = temp.sections.findIndex(
        (sect) => sect.sectionId === section_id
      );
      temp.sections[sectionIndex].url = "";
      setTemplateLink({ ...temp });
    }
  }

  return (
    <div className="flex items-center mt-10 space-x-3 ">
      <Item
        label={sectionName}
        section_id={section_id}
        prevData={prevData}
        setTemplateLink={setTemplateLink}
        link={url}
        setIsUploading={setIsUploading}
        isPredictedPercentile={isPredictedPercentile}
      />
      <Cancel className="cursor-pointer" onClick={clearFunction} />
    </div>
  );
}

browse.propTypes = {
  sectionName: propTypes.string,
  url: propTypes.string,
  section_id: propTypes.string,
  prevData: propTypes.object,
  setTemplateLink: propTypes.func,
  setIsUploading: propTypes.func,
  isPredictedPercentile: propTypes.bool,
};
export default browse;
