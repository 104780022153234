import React, { useState } from "react";
import Header from "../header/Header";
import BreadCrumb from "../global/breadcrumbs/BreadCrumb";
import DropDown from "../global/fields/DropDown";
import DropDownForDuration from "../../components/home/blogs/Dropdown";
import { useLocation } from "react-router-dom";
import { apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import { useQuery } from "react-query";
import ReactQuill from "../global/editor/ReactQuill";
import { useHistory } from "react-router-dom";
import { UploadFileToAWS } from "../../AWS/uploadFile";
import { useSelector } from "react-redux";
import Assessment from "./Assessment";

const Addasset = () => {
  const history = useHistory();
  const login_Details = useSelector((state) => state?.loginData?.LoginDetails);
  const location = useLocation();

  const [isUploading, setIsUploading] = useState(false);

  const dataValues = location?.state?.contentRepoData;
  const AssetType = [
    { id: 1, productGroupName: "Video" },
    { id: 2, productGroupName: "Assessment" },
    { id: 3, productGroupName: "Pdf" },
    { id: 4, productGroupName: "HTML" },
  ];
  const videoType = [
    { id: 1, productGroupName: "Vimeo" },
    { id: 2, productGroupName: "YouTube" },
    { id: 3, productGroupName: "Video Cypher" },
  ];

  // API call for asset tags ===============>

  const { data: tagsData } = useQuery(`${endpoint.tags_data.key}`, () =>
    apirequest({
      url: `${endpoint.tags_data.addr}`,
    })
  );

  // common states in asset form ;
  const [assetName, setAssetName] = useState("");
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [assetType, setAssetType] = useState("");
  const [thumbnailLink, setThumbnailLink] = useState("");
  const [assetTags, setAssetTags] = useState([]);

  // states based on type ==============>

  // Declaring States required for video type =================>
  const [videoPlayer, setVideoPlayer] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  // state based on pdf type ==============>
  const [pdfAuthorName, setPdfAuthorName] = useState("");
  const [pdfDescription, setPdfDescription] = useState("");
  const [pdfLink, setPdfLink] = useState("");

  // States based on Html type=========>
  const [editorDescription, setEditorDescription] = useState("");
  const [htmlAuthor, setHtmlAuthor] = useState("");

  // states based on Assessment ==============>
  const [assessmentId, setAssessmentId] = useState("");
  const [assessmentType, setAssessmentType] = useState("");
  const [assessmentInterface, setAssessmentInterface] = useState("");
  const [noOfQuestions, setNoOfQuestions] = useState("");
  const [passingThreshold, setPassingThreshold] = useState("");
  const [activeTab, setActiveTab] = useState(false);
  const isCrossOut = activeTab ? "1" : "0";
  const [activeTab2, setActiveTab2] = useState(false);
  const isMarkForReview = activeTab2 ? "1" : "0";
  const [activeString, setActiveString] = useState("inactive");
  // states for Basic
  const [isAdaptive, setIsAdaptive] = useState(false);
  const [predictedCatPercentile, setPredictedCatPercentile] = useState(false);
  const [cerebryTopicId, setcerebryTopicId] = useState("");
  const [isProctored, setIsProctored] = useState(false);
  const [releaseDate, setReleaseDate] = useState("DD : MM : YYYY");
  const [endDate, setEndDate] = useState("DD : MM : YYYY");
  const [slotStartDate, setSlotStartDate] = useState("DD : MM : YYYY");
  const [slotEndDate, setSlotEndDate] = useState("DD : MM : YYYY");
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [startSecond, setStartSecond] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(
    Number(startHour) === Number(endHour) ? 1 : 0
  );
  const [endSecond, setEndSecond] = useState(0);
  // states for cutOFF
  const [cutOffCategories, setCutOffCategories] = useState([]);
  // states for Percentile and Rank for assessment
  const obj1 = {
    overAll: "",
  };
  const [percentile, setPercentile] = useState(obj1);
  const [predictedPercentile, setPredictedPercentile] = useState(obj1);
  const [rank, setRank] = useState("");
  // Detailed analytics for assessment
  const [notes, setNotes] = useState("");
  const [detailAnalyticsTemplateLink, setDetailAnalyticsTemplateLink] =
    useState("");
  // duration for assessment =============>
  const [assessmentHour, setAssessmentHour] = useState(0);
  const [assessmentMinutes, setAssessmentMinutes] = useState(0);
  const [assessmentSeconds, setAssessmentSeconds] = useState(0);

  // common states among asset types =========>
  const [description, setDescription] = useState("");
  const [author, setAuthor] = useState("");

  /* API Call To Add Asset */
  const fetchAddAssetPayload = () => {
    return apirequest({
      url: endpoint.addAssetUsingContentRepository.addr,
      method: "POST",
      data: {
        assetName: assetName,
        duration: `${hour < 9 ? "0" + hour : hour}:${
          minutes < 9 ? "0" + minutes : minutes
        }:${seconds < 9 ? "0" + seconds : seconds}`,
        thumbnailLink: thumbnailLink,
        assetType: assetType,
        isActive: "1",
        createdBy: login_Details.userImsPin,
        videoPlayer: videoPlayer,
        videoUrl: videoPlayer === "Video Cypher" ? "" : videoUrl,
        videoCypherId: videoPlayer === "Video Cypher" ? videoUrl : "",
        videoDescription: description,
        videoAuthorName: author,
        assessmentId: assessmentId,
        assessmentType: assessmentType,
        assessmentInterface: assessmentInterface,
        totalQuestions: noOfQuestions,
        crossOut: isCrossOut,
        passingThreshold: passingThreshold,
        markForReview: isMarkForReview,
        type: activeString === "inactive" ? "untimed" : "timed",
        assessmentDuration: `${
          assessmentHour < 9 ? "0" + assessmentHour : assessmentHour
        }:${
          assessmentMinutes < 9 ? "0" + assessmentMinutes : assessmentMinutes
        }:${
          assessmentSeconds < 9 ? "0" + assessmentSeconds : assessmentSeconds
        }`,
        pdfUrl: pdfLink,
        pdfDescription: pdfDescription,
        pdfAuthorName: pdfAuthorName,
        htmlContent: editorDescription,
        authorName: htmlAuthor,
        tagName: assetTags,
        contentRepositoryId: dataValues?.contentRepositoryId,
        adaptivePractice: isAdaptive ? "1" : "0",
        predictedCatPercentile: predictedCatPercentile ? "1" : "0",
        cerebryTopicId: cerebryTopicId,
        proctored: isProctored ? "1" : "0",
        releaseDate:
          releaseDate === "DD : MM : YYYY" || !releaseDate ? "" : releaseDate,
        startDate: "",
        endDate: endDate === "DD : MM : YYYY" || !endDate ? "" : endDate,
        slotStartDate:
          slotStartDate === "DD : MM : YYYY" || !slotStartDate
            ? ""
            : slotStartDate +
              " " +
              `${+startHour < 9 ? "0" + +startHour : +startHour}:${
                +startMinute < 9 ? "0" + +startMinute : +startMinute
              }:${+startSecond < 9 ? "0" + +startSecond : +startSecond}`,
        slotEndDate:
          slotEndDate === "DD : MM : YYYY" || !slotEndDate
            ? ""
            : slotEndDate +
              " " +
              `${+endHour < 9 ? "0" + +endHour : +endHour}:${
                +endMinute < 9 ? "0" + +endMinute : +endMinute
              }:${+endSecond < 9 ? "0" + +endSecond : +endSecond}`,
        cutOff: cutOffCategories,
        percentile,
        officialTestPredictedPercentile: predictedPercentile,
        ranks: rank,
        questionType: {
          Notes: notes,
          url: detailAnalyticsTemplateLink,
        },
      },
    });
  };

  const { refetch } = useQuery(
    `${endpoint.addAssetUsingContentRepository.key}`,
    fetchAddAssetPayload,
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        alert(data.data.message);
        window.location.reload(false);
      },
      onError: (err) => {
        // setAddAssetFlag(false);
        alert(err.data.message);
      },
    }
  );

  const validateObj = (obj) => {
    let result = Object.keys(obj).filter((item) => {
      if (obj[item] === "") {
        return item;
      }
    });
    if (result.length > 0) {
      alert(`Please Enter Valid ${result.toString()}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (assetType) {
      case "Video":
        {
          let obj =
            videoPlayer === "Video Cypher"
              ? { assetName, videoPlayer, videoUrl }
              : { assetName, videoPlayer, videoUrl, description, author };
          Object.keys(obj).forEach((k) => (obj[k] = obj[k].trim()));
          if (Object.values(obj).includes("")) {
            validateObj(obj);
          } else {
            refetch();
          }
        }
        break;
      case "Pdf":
        {
          let obj = { assetName, pdfAuthorName, pdfDescription, pdfLink };
          Object.keys(obj).forEach((k) => (obj[k] = obj[k].trim()));
          if (Object.values(obj).includes("")) {
            validateObj(obj);
          } else {
            refetch();
          }
        }
        break;
      case "HTML":
        {
          let obj = { assetName, editorDescription, htmlAuthor };
          Object.keys(obj).forEach((k) => (obj[k] = obj[k].trim()));
          if (Object.values(obj).includes("")) {
            validateObj(obj);
          } else if (editorDescription.length === 0) {
            alert("HTML Asset Shouldn't be Empty");
          } else if (
            editorDescription.length === 11 &&
            editorDescription.includes("br")
          ) {
            alert("HTML Asset Shouldn't be Empty");
          } else if (editorDescription.split(" ").join("").length === 7) {
            alert("Please Enter Valid HTML Asset");
          } else {
            refetch();
          }
        }
        break;
      case "Assessment": {
        let obj = {
          assetName,
          assessmentId,
        };
        Object.keys(obj).forEach((k) => (obj[k] = obj[k].trim()));
        if (Object.values(obj).includes("")) {
          validateObj(obj);
        } else {
          refetch();
        }
      }
    }
  };

  const handleBrowse = (event) => {
    event.preventDefault();
    setThumbnailLink(event.target?.files[0]?.name);
    const fileDate = new Date()
      .toLocaleString({ hour12: true })
      .toString()
      .split("/");
    if (event.target?.files[0]) {
      const val = UploadFileToAWS({
        key: "addAssetContentRepository",
        file: event.target?.files[0],
        path:
          "Asset/ContentRepositoryAssets/" +
          fileDate[2].toString().split(",")[0] +
          "/" +
          event.target?.files[0].name.toString().split(".")[0] +
          "_" +
          login_Details.userImsPin +
          "_" +
          fileDate[2].toString().split(/[, ]+/)[1] +
          "." +
          event.target?.files[0].name.toString().split(".")[1],
      });
      {
        val.then((item) => setThumbnailLink(item));
      }
    } else {
      alert("No files available!");
    }
  };

  // API for fetching CutOFF categories
  const fetchSectionsData = () => {
    return apirequest({
      url: endpoint.getTestSectionsData.addr,
      method: "POST",
      data: {
        assessmentId: assessmentId,
      },
    });
  };

  useQuery(
    [endpoint.getTestSectionsData.key, assessmentId],
    fetchSectionsData,
    {
      enabled: assessmentId !== "",
      select: (res) => res.data.data,
      onSuccess: (data) => {
        setCutOffCategories(data);
        let sections = data[0].sections;
        const obj1 = {
          overAll: "",
          sections: [],
        };
        const obj2 = {
          overAll: "",
          sections: [],
        };
        sections.forEach((section) => {
          obj1.sections = obj1.sections.concat({
            section_id: section.section_id,
            url: "",
            name: section.name,
          });
          obj2.sections = obj2.sections.concat({
            section_id: section.section_id,
            url: "",
            name: section.name,
          });
        });
        setPercentile({ ...obj1 });
        setPredictedPercentile({ ...obj2 });
      },
    }
  );

  return (
    <>
      <Header />
      <div className="mt-6 ml-16">
        <BreadCrumb type={"editCourse"} />
      </div>
      <div className="mt-10 mx-6">
        <div className="pb-[5rem]">
          <div className="courseTable__body md:mx-7 mt-4 shadow-outer py-12 px-8 rounded-xl bg-white pb-[2%]">
            <form
              className="flex flex-col justify-start font-ibm "
              id="addAssetForm"
            >
              <div className="mb-4">
                <label className="inline-block w-[180px] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3">
                  Asset Name{" "}
                </label>
                <input
                  className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-7/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                  type="text"
                  placeholder="Enter Asset Name"
                  onChange={(e) => setAssetName(e.target.value)}
                  name="assetName"
                  required
                  autoComplete="off"
                />
              </div>
              <div className="flex mb-4">
                <label className="inline-block w-[180px] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3">
                  Duration
                </label>
                <div className="flex space-x-3 mt-3 ">
                  <DropDownForDuration
                    minOffset={0}
                    maxOffset={23}
                    text={"Hrs"}
                    setSelectedValue={setHour}
                    selectedValue={hour}
                  />
                  <DropDownForDuration
                    minOffset={0}
                    maxOffset={59}
                    text={"Mins"}
                    setSelectedValue={setMinutes}
                    selectedValue={minutes}
                  />
                  <DropDownForDuration
                    minOffset={0}
                    maxOffset={59}
                    text={"Sec"}
                    setSelectedValue={setSeconds}
                    selectedValue={seconds}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="mb-4 w-[60rem]">
                  <label className="inline-block text-left whitespace-nowrap w-[180px] font-normal font-ibm text-lg mt-3">
                    Thumbnail
                  </label>
                  <input
                    className="bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-6/12 placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                    placeholder="Paste Thumbnail Link"
                    name="thumbnailLink"
                    value={thumbnailLink}
                    readOnly={true}
                    autoComplete="off"
                  />
                </div>
                <label className="bg-primary-200 px-10 pt-[14px] cursor-pointer pb-2 mr-[25rem] h-[3.0rem] rounded-xl text-white text-sm font-semibold">
                  <input
                    className="hidden "
                    type="file"
                    multiple
                    onChange={handleBrowse}
                    accept=".webp"
                  />
                  Browse
                </label>
              </div>
              <div className="flex mb-4">
                <label className="inline-block w-[180px] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3">
                  Asset Tags{" "}
                </label>
                {
                  <DropDown
                    placeholder="Add Asset Type"
                    options={tagsData?.data.data}
                    setUpdatedOptions={setAssetTags}
                    setFieldValue={setAssetTags}
                    type={"multiselect"}
                    width={"w-[22rem]"}
                  />
                }
              </div>
              <div className="flex mb-4">
                <label className="inline-block w-[180px] text-left whitespace-nowrap font-normal font-ibm text-lg mt-3">
                  Asset Type{" "}
                </label>
                <DropDown
                  placeholder={"Search exisiting or Create new tags"}
                  options={AssetType}
                  setUpdatedOptions={setAssetType}
                  setFieldValue={setAssetType}
                  type={"singleSelect"}
                  width={"w-[35rem]"}
                />
              </div>

              {/* Video Type starts here ======================> */}
              {assetType === "Video" && (
                <React.Fragment>
                  <div className="flex mb-4">
                    <label className="inline-block w-[10%] text-left whitespace-nowrap  mr-[76px] font-normal font-ibm text-lg mt-3">
                      Video Player{" "}
                    </label>
                    <DropDown
                      placeholder={"Select the Video"}
                      options={videoType}
                      setUpdatedOptions={setVideoPlayer}
                      setFieldValue={setVideoPlayer}
                      type={"singleSelect"}
                      width={"w-[35rem]"}
                    />
                  </div>
                  <div className="flex mb-4">
                    <label className="inline-block w-[10%] text-left whitespace-nowrap  mr-[60px] font-normal font-ibm text-lg mt-3">
                      {videoPlayer === "Video Cypher"
                        ? "Video Id"
                        : "Video Link"}
                    </label>
                    <input
                      className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                      placeholder="Paste the Video Link"
                      name="videoUrl"
                      onChange={(e) => setVideoUrl(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  {
                    <div className="flex mb-4">
                      <label className="inline-block w-[10%] text-left whitespace-nowrap mr-[77px] font-normal font-ibm text-lg mt-3">
                        Video Description
                      </label>
                      <ReactQuill
                        customStyle={
                          "border-2 border-secondary-300 border-solid rounded-sm h-60 max-w-3xl"
                        }
                        value={description}
                        setValue={setDescription}
                      />
                    </div>
                  }
                  {
                    <div className="flex mb-4 mt-16">
                      <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">
                        Author
                      </label>
                      <input
                        className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                        placeholder="This Video Authorized by"
                        name="videoAuthorName"
                        onChange={(e) => setAuthor(e.target.value)}
                      />
                    </div>
                  }
                </React.Fragment>
              )}

              {/* AssetMent type starts here=========================>  */}

              {assetType === "Assessment" && (
                <Assessment
                  assessmentId={assessmentId}
                  setAssessmentId={setAssessmentId}
                  assessmentType={assessmentType}
                  setAssessmentType={setAssessmentType}
                  setAssessmentInterface={setAssessmentInterface}
                  setNoOfQuestions={setNoOfQuestions}
                  setAssessmentHour={setAssessmentHour}
                  setAssessmentMinutes={setAssessmentMinutes}
                  setAssessmentSeconds={setAssessmentSeconds}
                  setPassingThreshold={setPassingThreshold}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  activeTab2={activeTab2}
                  setActiveTab2={setActiveTab2}
                  activeString={activeString}
                  setActiveString={setActiveString}
                  assessmentHour={assessmentHour}
                  assessmentMinutes={assessmentMinutes}
                  assessmentSeconds={assessmentSeconds}
                  isAdaptive={isAdaptive}
                  predictedCatPercentile={predictedCatPercentile}
                  setPredictedCatPercentile={setPredictedCatPercentile}
                  setIsAdaptive={setIsAdaptive}
                  cerebryTopicId={cerebryTopicId}
                  setcerebryTopicId={setcerebryTopicId}
                  isProctored={isProctored}
                  setIsProctored={setIsProctored}
                  releaseDate={releaseDate}
                  setReleaseDate={setReleaseDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  slotStartDate={slotStartDate}
                  setSlotStartDate={setSlotStartDate}
                  slotEndDate={slotEndDate}
                  setSlotEndDate={setSlotEndDate}
                  startHour={startHour}
                  setStartHour={setStartHour}
                  startMinute={startMinute}
                  setStartMinute={setStartMinute}
                  startSecond={startSecond}
                  setStartSecond={setStartSecond}
                  endHour={endHour}
                  setEndHour={setEndHour}
                  endMinute={endMinute}
                  setEndMinute={setEndMinute}
                  endSecond={endSecond}
                  setEndSecond={setEndSecond}
                  percentile={percentile}
                  setPercentile={setPercentile}
                  predictedPercentile={predictedPercentile}
                  setPredictedPercentile={setPredictedPercentile}
                  rank={rank}
                  setRank={setRank}
                  notes={notes}
                  setNotes={setNotes}
                  detailAnalyticsTemplateLink={detailAnalyticsTemplateLink}
                  setDetailAnalyticsTemplateLink={
                    setDetailAnalyticsTemplateLink
                  }
                  cutOffCategories={cutOffCategories}
                  setCutOffCategories={setCutOffCategories}
                  setIsUploading={setIsUploading}
                />
              )}

              {/* PDF Type starts here ==================> */}
              {assetType === "Pdf" && (
                <React.Fragment>
                  <div className="flex mb-4">
                    <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">
                      PDF URL
                    </label>
                    <input
                      className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                      placeholder="Paste the PDF URL here"
                      type="link"
                      name="pdfUrl"
                      onChange={(e) => setPdfLink(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex mb-4">
                    <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">
                      PDF Description
                    </label>
                    <textarea
                      className="ml-5 bg-white border-2 border-secondary-300 h-36 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                      placeholder="Enter Short Description about the Pdf"
                      name="pdfDescription"
                      onChange={(e) => setPdfDescription(e.target.value)}
                    />
                  </div>
                  <div className="flex mb-4">
                    <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">
                      Author
                    </label>
                    <input
                      className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                      placeholder="This Video Authorized by"
                      name="pdfAuthorName"
                      onChange={(e) => setPdfAuthorName(e.target.value)}
                    />
                  </div>
                </React.Fragment>
              )}
              {/* HTML type starts here ============================> */}
              {assetType === "HTML" && (
                <React.Fragment>
                  <div className="flex mb-4">
                    <label className="inline-block w-[10%] text-left whitespace-nowrap mr-[77px] font-normal font-ibm text-lg mt-3">
                      HTML Content
                    </label>
                    <ReactQuill
                      customStyle={
                        "border-2 border-secondary-300 border-solid rounded-sm h-60 max-w-3xl"
                      }
                      value={editorDescription}
                      setValue={setEditorDescription}
                    />
                  </div>
                  <div className="flex mb-4 mt-16">
                    <label className="inline-block w-[10%] text-left whitespace-nowrap mr-14 font-normal font-ibm text-lg mt-3">
                      Author
                    </label>
                    <input
                      className="ml-5 bg-white border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 focus:outline-none w-[35rem] placeholder-bold font-semibold text-XL focus:placeholder-transparent"
                      placeholder="This HTML is Authorized by"
                      name="authorName"
                      onChange={(e) => setHtmlAuthor(e.target.value)}
                    />
                  </div>
                </React.Fragment>
              )}
            </form>
          </div>

          <div className="flex items-center justify-center mt-7 pr-20">
            <button
              disabled={!!isUploading}
              form="addAssetForm"
              type="submit"
              className="bg-primary-200 text-white font-ibm px-12 rounded-xl hover:opacity-75 shadow-outer py-[10px] text-base font-medium mr-10 cursor-pointer disabled:bg-secondary-400 disabled:cursor-not-allowed"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              className="text-black boxShadow-forButton font-ibm px-10 rounded-xl hover:opacity-75 shadow-outer text-base font-medium py-[10px] cursor-pointer"
              onClick={history.goBack}
            >
              Exit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addasset;
