import React from "react";
import ReactQuill from "../global/editor/ReactQuill";
import FileBrowse from "./PercentileAndRank/Item";
import propTypes from "prop-types";
import { endpoint } from "../../API/config";
import { ReactComponent as Cancel } from "../../assets/icons/cancelBlack.svg";

function DetailAnalytics({
  notes,
  setNotes,
  detailAnalyticsTemplateLink,
  setDetailAnalyticsTemplateLink,
  assessmentId,
  setIsUploading,
}) {
  function handleClick() {
    window.open(
      `${process.env.REACT_APP_ENV}${endpoint.getDetailedAnalyticsTemplate.addr}/${assessmentId}`
    );
  }

  return (
    <div>
      <>
        <label className="inline-block text-left whitespace-nowrap font-normal font-ibm text-lg mb-5">
          Notes
        </label>
        <ReactQuill
          customStyle={
            "border-2 border-secondary-300 border-solid rounded-sm h-60 max-w-3xl"
          }
          value={notes}
          setValue={setNotes}
        />
      </>
      <div className="flex mt-7 mb-10 ">
        <p className="font-normal font-ibm text-xl mr-4 ">Question Type</p>
        <button
          className="text-primary-700 underline"
          onClick={(e) => handleClick(e)}
        >
          Template
        </button>
      </div>
      <div className="flex space-x-2 items-center">
        <FileBrowse
          link={detailAnalyticsTemplateLink}
          setTemplateLink={setDetailAnalyticsTemplateLink}
          setIsUploading={setIsUploading}
        />
        <Cancel
          className="cursor-pointer"
          onClick={() => setDetailAnalyticsTemplateLink("")}
        />
      </div>
    </div>
  );
}

DetailAnalytics.propTypes = {
  notes: propTypes.string,
  setNotes: propTypes.func,
  detailAnalyticsTemplateLink: propTypes.string,
  setDetailAnalyticsTemplateLink: propTypes.func,
  assessmentId: propTypes.string,
  setIsUploading: propTypes.func,
};

export default DetailAnalytics;
