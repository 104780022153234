import React from "react";
import propTypes from "prop-types";
import Browse from "./browse";

function PercentileAndRank({
  percentile,
  setPercentile,
  predictedPercentile,
  setPredictedPercentile,
  rank,
  setRank,
  setIsUploading,
}) {
  return (
    <div>
      <div className="grid grid-cols-2">
        {/* PERCENTILE */}
        <div>
          <div className="flex items-end">
            <p className="font-normal font-ibm text-xl mr-4 ">Percentile</p>
            <a
              className="text-primary-700 underline"
              href="https://staging-assets-myimsv3.s3.ap-south-1.amazonaws.com/Asset/TestSettingsTemplates/percentile_template.xlsx"
            >
              Template
            </a>
          </div>

          {/* file upload */}
          <div className="ml-8">
            <Browse
              setIsUploading={setIsUploading}
              sectionName={"OverAll"}
              prevData={percentile}
              setTemplateLink={setPercentile}
              url={percentile.overAll}
            />
            {percentile?.sections?.map((section) => {
              return (
                <Browse
                  setIsUploading={setIsUploading}
                  key={section.sectionId}
                  section_id={section.sectionId || section.section_id}
                  sectionName={section.sectionName || section.name}
                  prevData={percentile}
                  setTemplateLink={setPercentile}
                  url={section.url}
                />
              );
            })}
          </div>
        </div>

        {/* PREDICTED PERCENTILE */}
        <div>
          <div className="flex items-end">
            <p className="font-normal font-ibm text-xl mr-4 ">
              Official Test Predicted Percentile
            </p>
            <a
              className="text-primary-700 underline"
              href="https://staging-assets-myimsv3.s3.ap-south-1.amazonaws.com/Asset/TestSettingsTemplates/official_test_predicted_percentile_template.xlsx"
            >
              Template
            </a>
          </div>

          {/* file upload */}
          <div className="ml-8">
            <Browse
              setIsUploading={setIsUploading}
              sectionName={"OverAll"}
              prevData={predictedPercentile}
              isPredictedPercentile={true}
              setTemplateLink={setPredictedPercentile}
              url={predictedPercentile.overAll}
            />
            {/* {predictedPercentile?.sections?.map(section => (
              <Browse
                key={section.sectionId}
                section_id={section.sectionId || section.section_id}
                sectionName={section.sectionName || section.name}
                prevData={predictedPercentile}
                setTemplateLink={setPredictedPercentile}
                url={section.url}
              />
            ))} */}
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className="flex items-end">
          <p className="font-normal font-ibm text-xl mr-4 ">Rank</p>
          <a
            className="text-primary-700 underline"
            href="https://staging-assets-myimsv3.s3.ap-south-1.amazonaws.com/Asset/TestSettingsTemplates/ranks_template.xlsx"
          >
            Template
          </a>
        </div>
        <div className="flex items-center mt-10 space-x-3 ">
          <Browse
            setIsUploading={setIsUploading}
            sectionName="rank"
            setTemplateLink={setRank}
            url={rank}
          />
        </div>
      </div>
    </div>
  );
}

PercentileAndRank.propTypes = {
  percentile: propTypes.object,
  setPercentile: propTypes.func,
  predictedPercentile: propTypes.object,
  setPredictedPercentile: propTypes.func,
  rank: propTypes.string,
  setRank: propTypes.func,
  setIsUploading: propTypes.func,
};

export default PercentileAndRank;
