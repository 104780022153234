import React, { useEffect } from "react";
import logo from "../assets/icons/ims-logo.svg";

import Header from "../components/header/Header";
import Banner from "./Banner";
import { getLoginDetails } from "../redux/features/login/loginSlice";
import { apirequest } from "../API/api";
import { endpoint } from "../API/config";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  getHeaderDetail,
  getSubheaderOption,
  getCurrentSubHeaderTab,
} from "../redux/features/header/headerSlice";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const Home = () => {
  const dispatch = useDispatch();
  // api for login
  // Getting cookies from IMS
  // const cookies = new Cookies();
  let adminData = null;
  adminData = localStorage.getItem("loginInfo");
  if (!adminData) {
    adminData = Cookies.get("loginInfo");
  }
  if (adminData) {
    var bytes = CryptoJS.AES.decrypt(
      adminData,
      process.env.REACT_APP_COOKIE_SECRET
    );
    if (bytes) {
      var decryptedLoginInfo =
        bytes && JSON.parse(bytes?.toString(CryptoJS?.enc?.Utf8));
    }
  } else {
    console.log("Cookie not found");
  }

  const fetchLoginDetails = () => {
    return apirequest({
      url: `${endpoint.login_admin.addr}`,
      method: "post",
      // data: decryptedLoginInfo,
      headers: { "access-token": decryptedLoginInfo?.token },
    });
  };

  //API request for login
  const {
    data: loginData,
    isSuccess: login_data_status,
    isError,
  } = useQuery(endpoint.login_admin.key, fetchLoginDetails, {
    enabled: true,
    retry: false,
    onSuccess: (data) => dispatch(getLoginDetails(data.data.data)),
    onError: () => {
      window.location.href = process.env.REACT_APP_IMSROUTE;
    },
    refetchOnMount: true,
  });
  login_data_status &&
    dispatch(
      getLoginDetails({
        ...loginData.data.data,
        token: decryptedLoginInfo.token,
      })
    );

  useEffect(() => {
    dispatch(getCurrentSubHeaderTab(""));
    dispatch(getHeaderDetail(""));
    dispatch(getSubheaderOption(""));
  }, []);
  useEffect(() => {
    if (loginData?.student_imspin) {
      window.location.replace(process.env.REACT_APP_IMSROUTE);
    }
  }, []);

  return (
    <div className="flex flex-col">
      {isError && (
        <img
          className="h-64 w-64 top-40 left-1/2 cursor-pointer flex justify-around -ml-20 absolute "
          src={logo}
          alt="My IMS Logo"
          onClick={() => history.push("/")}
        />
      )}

      {login_data_status && (
        <div>
          <Header />
          <Banner />
        </div>
      )}
    </div>
  );
};

export default Home;
