import axios from "axios";
import config from "../API/config";

import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const axiosInstance = axios.create({ baseURL: config.staging });

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      window.location.href = process.env.REACT_APP_IMSROUTE;
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.href = process.env.REACT_APP_IMSROUTE;
    }
    return Promise.reject(error);
  }
);

export const UploadFileToAWS = async ({ ...options }) => {
  axiosInstance.defaults.headers.common.Authorization = "Bearer";

  let adminData = null;
  let info = null;

  adminData = localStorage.getItem("loginInfo");

  if (!adminData) {
    adminData = Cookies.get("loginInfo");
  }

  if (adminData) {
    var bytes = CryptoJS.AES.decrypt(
      adminData,
      process.env.REACT_APP_COOKIE_SECRET
    );
    info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  try {
    const formData = new FormData();
    formData.append("upload_file", options.file);

    const { data } = await axiosInstance.post("/file/upload/", formData, {
      headers: {
        "access-token": info?.token,
      },
    });
    return data?.file_url;
  } catch (e) {
    if (e) {
      alert("Upload Unsuccessful. Please Try Again!");
    }
  }
};
